import {State} from "../State";
import {networkManager} from "../../index";

export interface SetAuthenticatedStateAction {
    type: 'SetAuthenticatedStateAction'
    token: string | undefined
    name: string | undefined
}

export function buildSetAuthenticatedStateAction(token: string | undefined, name: string | undefined): SetAuthenticatedStateAction {
    return {
        type: 'SetAuthenticatedStateAction',
        token: token,
        name: name
    }
}

export function reduceSetAuthenticatedStateAction(state: State, action: SetAuthenticatedStateAction): State {
    if(action.token !== undefined) {
        localStorage.setItem('localLoginToken', action.token)
        if(!networkManager.compareToken(action.token)) {
            networkManager.setAuthenticated(action.token)
        }
    } else {
        localStorage.removeItem('localLoginToken')
        networkManager.setUnauthenticated()
    }
    return {
        ...state,
        authentication: {
            ...state.authentication,
            isAuth: action.token !== undefined,
            authToken: action.token !== undefined ? action.token : '',
            name: action.name
        }
    }
}