
export function createSignUpClientRequest(name: string, password: string): SignUpClientRequest {
    return {
        route: 'signup',
        name: name,
        password: password
    }
}

export interface SignUpClientRequest {
    route: 'signup'
    name: string
    password: string
}

export type SignUpServerResponse = SuccessSignUpServerResponse | FailureSignUpServerResponse

export type SignUpServerResponseError = 'name-taken' | 'pw-too-short'

interface SuccessSignUpServerResponse {
    status: 'success'
    name: string
}
interface FailureSignUpServerResponse {
    status: 'failure'
    error: SignUpServerResponseError
}

