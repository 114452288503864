import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './elements.css';
import {Provider} from "react-redux";
import {store} from "./store";
import Layout from "./Components/Layout";
import {NetworkManager} from "./Api/NetworkManager";
// @ts-ignore
import initReactFastclick from 'react-fastclick'
initReactFastclick()

const root = ReactDOM.createRoot(document.getElementById('root')!);

export const networkManager = new NetworkManager()

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Layout/>
        </Provider>
    </React.StrictMode>
);

