import {Action} from "./Action";
import {buildInitialState, State} from "./State";
import {reduceChangeViewAction} from "./Actions/ChangeViewAction";
import {reduceSetAuthenticatedStateAction} from "./Actions/SetAuthenticatedStateAction";
import {reduceSetOnlineFriendsAction} from "./Actions/SetOnlineFriendsAction";

export function MainReducer(state: State | undefined, action: Action): State {
    if (state === undefined) {
        return buildInitialState();
    }

    switch (action.type) {
        case "ChangeViewAction":
            return reduceChangeViewAction(state, action);
        case "SetAuthenticatedStateAction":
            return reduceSetAuthenticatedStateAction(state, action);
        case "SetOnlineFriendsAction":
            return reduceSetOnlineFriendsAction(state, action);
    }

    return state;
}