export function emptyNetworkState(): NetworkGameState {
    return {
        playerList: [],
        plankList: [],
    };
}

export interface NetworkGameState {
    playerList: NetworkPlayer[]
    plankList: NetworkPlank[]
}

export type NetworkGameObjectEffect = HitNetworkGameObjectEffect

export interface HitNetworkGameObjectEffect {
    type: 'hit'
    position: Vec2
}

export interface NetworkPlayer {
    name: string;
    id: string
    projectile: NetworkProjectile;
    isPlayerOwned: boolean;
    score: number
}

export interface NetworkProjectile {
    position: Vec2;
    velocity: Vec2;
    radius: number;
    effects: NetworkGameObjectEffect[]
}

export interface NetworkPlank {
    level: number
    position: Vec2;
    velocity: Vec2;
    size: Vec2;
    lifePoints: number
    effects: NetworkGameObjectEffect[]
}

interface Vec2 {
    x: number;
    y: number;
}