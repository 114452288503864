import * as React from 'react';
import {Dispatch} from 'react';
import {connect} from "react-redux";
import {ScreenView} from "../Layout";
import {State} from "../../Redux/State";
import {Action} from "../../Redux/Action";
import './userTab.css';
import {AccountCircle} from "@mui/icons-material";
import UserTabLogin from "./Login/UserTabLogin";
import {AuthenticationState} from "../../Redux/State/AuthenticationState";
import UserTabRegister from "./Register/UserTabRegister";
import UserTabAuth from "./Auth/UserTabAuth";
import {networkManager} from "../../index";
import {LobbyServerMessage, LobbyServerMessageType} from "../../Api/LobbyNetworkMessages/LobbyServerMessage";
import {buildSetOnlineFriendsAction} from "../../Redux/Actions/SetOnlineFriendsAction";
import {FriendListEntry} from "../../Api/LobbyNetworkMessages/Entity/FriendListEntry";

interface ComponentProps {
    freeSideSpace: number;
    freeTopBottomSpace: number;
    children: React.ReactNode;
}

interface StateProps {
    view: ScreenView;
    auth: AuthenticationState;
}

interface DispatchProps {
    onlineFriendsUpdate: (onlineFriends: FriendListEntry[]) => void
}

const noAuthUserTabs = [
    'login',
    'register'
]

interface InternalState {
    openTabClassAdd: 'opened' | 'closed' | '';
    userTabView: 'account' | 'login' | 'register';
}

type Props = StateProps & DispatchProps & ComponentProps

class UserTab extends React.Component<Props, InternalState> {

    static USER_TAB_WIDTH: number = 280;

    static mapStateProps(state: State): StateProps {
        return {
            view: state.view,
            auth: state.authentication
        };
    }

    static mapDispatchProps(dispatch: Dispatch<Action>): DispatchProps {
        return {
            onlineFriendsUpdate: (onlineFriends: FriendListEntry[]) => dispatch(buildSetOnlineFriendsAction(onlineFriends))
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            openTabClassAdd: '',
            userTabView: props.auth.isAuth ? 'account' : 'login'
        };
    }

    componentDidMount() {
        networkManager.lobbyWs.setReceiveCallback((data: LobbyServerMessage) => {
            console.log('received lobby message', data)
            switch (data.type) {
                case LobbyServerMessageType.AUTHENTICATION_ANSWER:
                    this.props.onlineFriendsUpdate(data.onlineFriends)
                    break
                case LobbyServerMessageType.UPDATE_FRIEND_LIST:
                    this.props.onlineFriendsUpdate(data.onlineFriends)
                    break
            }
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<InternalState>, snapshot?: any) {
        if(noAuthUserTabs.findIndex(s => this.state.userTabView === s) !== -1) {
            if(this.props.auth.isAuth) {
                this.setState({
                    ...this.state,
                    userTabView: 'account'
                })
            }
        } else {
            if(!this.props.auth.isAuth) {
                this.setState({
                    ...this.state,
                    userTabView: 'login'
                })
            }
        }
    }

    render() {
        const renderTabBesidesContent: boolean = this.props.freeSideSpace > UserTab.USER_TAB_WIDTH;
        return (
            <React.Fragment>
                {renderTabBesidesContent && this.userTabBesidesContent()}
                {!renderTabBesidesContent && this.userTabOverContent()}
            </React.Fragment>
        );
    }

    userTabBesidesContent() {
        return (
            <div className={'user-tab-wrap-flex'}>
                <div className={'user-tab'} style={{width: UserTab.USER_TAB_WIDTH}}>
                    {this.userTab(false)}
                </div>
                <div className={'content-mount'}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    userTabOverContent() {
        const iconText = (this.props.auth.isAuth) ? this.props.auth.name : 'Login';
        return (
            <div className={'user-tab-wrap-stacked'}>
                {
                    this.props.view === 'MENU' &&
                    this.state.openTabClassAdd !== 'opened' &&
                    <div
                        className={'user-tab-open-icon'}
                        style={{left: (this.props.freeSideSpace * 0.5), top: (this.props.freeTopBottomSpace * 0.5)}}
                        onClick={() => this.openUserTab()}
                    >
                        <div className={'icon-text'}>{iconText}</div>
                        <AccountCircle/>
                    </div>
                }
                <div className={'content-mount'} onClickCapture={() => this.closeUserTab()}>
                    {this.props.children}
                </div>
                <div className={'user-tab ' + this.state.openTabClassAdd}>
                    {this.userTab(true)}
                </div>
            </div>
        );
    }

    userTab(asOverlay: boolean) {
        const close = (asOverlay) ? () => this.closeUserTab() : undefined;
        switch (this.state.userTabView) {
            default:
            case 'login':
                return <UserTabLogin closeUserTab={close} openUserTabRegister={() => this.openRegisterTab()}/>;
            case 'register':
                return <UserTabRegister closeUserTab={close} openUserTabLogin={() => this.openLoginTab()}/>;
            case 'account':
                return <UserTabAuth closeUserTab={close}/>;
        }
    }

    openRegisterTab() {
        this.setState({
            ...this.state,
            userTabView: 'register'
        });
    }

    openLoginTab() {
        this.setState({
            ...this.state,
            userTabView: 'login'
        });
    }

    closeUserTab() {
        this.setState({
            ...this.state,
            openTabClassAdd: 'closed'
        });
    }

    openUserTab() {
        this.setState({
            ...this.state,
            openTabClassAdd: 'opened'
        });
    }

}

export default connect(UserTab.mapStateProps, UserTab.mapDispatchProps)(UserTab);