import {ScreenView} from "../Components/Layout";
import {AuthenticationState, buildInitialAuthenticationState} from "./State/AuthenticationState";
import {buildInitialFriendListState, FriendListState} from "./State/FriendListState";

export interface State {
    view: ScreenView;
    authentication: AuthenticationState;
    friendList: FriendListState;
}

export function buildInitialState(): State {
    return {
        view: 'MENU',
        authentication: buildInitialAuthenticationState(),
        friendList: buildInitialFriendListState(),
    };
}