import {Vec2} from "../Math/Vec2";
import * as React from 'react';
import {NetworkProjectile} from "../Network/NetworkGameState/NetworkGameState";
import {GameObjectEffect} from "../GameObjectEffect";

interface ComponentProps {
    scaling: number;
    projectile: NetworkProjectile;
    ownedByUser: boolean;
    updateTime: number;
}

export class Projectile extends React.Component<ComponentProps> {

    private effects: GameObjectEffect[] = [];

    render() {
        this.effects = this.effects.filter(e => !e.shouldDie(this.props.updateTime));
        this.props.projectile.effects.forEach(e => this.effects.push(new GameObjectEffect(e, this.props.updateTime, 120)));
        return (
            <React.Fragment>
                {
                    this.impacts().map((effect, i) => {
                        const style = this.impactPosition(effect.effect.position, effect.progress(this.props.updateTime));
                        return (
                            <React.Fragment key={'effect-' + i}>
                                <div className={'impact infront'} style={style}/>
                                <div className={'impact behind'} style={style}/>
                            </React.Fragment>
                        );
                    })
                }
                <div className={'projectile-position'} style={this.projectilePositionStyle()}>
                    <div className={'projectile' + this.resolveEffectClasses()} style={this.projectileStyle()}>
                        {
                            Projectile.DefaultStyle()
                        }
                    </div>
                </div>
            </React.Fragment>

        );
    }

    private impacts(): GameObjectEffect[] {
        return this.effects.filter(e => e.effect.type === 'hit');
    }

    private resolveEffectClasses(): string {
        let classes = '';
        if (this.props.ownedByUser) {
            classes += ' active';
        }
        if (this.effects.find(e => e.effect.type === 'hit') !== undefined) {
            classes += ' is-hit';
        }
        return classes;
    }

    private projectilePositionStyle(): React.CSSProperties {
        return {
            width: this.props.projectile.radius * 2 * this.props.scaling,
            height: this.props.projectile.radius * 2 * this.props.scaling,
            left: this.props.projectile.position.x * this.props.scaling,
            bottom: this.props.projectile.position.y * this.props.scaling,
        };
    }

    private projectileStyle(): React.CSSProperties {
        return {
            transform: 'rotate(' + this.rotation() + 'deg)'
        };
    }

    private rotation(): number {
        // α = arccos[(xa * xb + ya * yb) / (√(xa2 + ya2) * √(xb2 + yb2))]
        const upVec: Vec2 = {x: 0, y: 1};
        const cVec: Vec2 = normalise(this.props.projectile.velocity);

        const rotation = Math.acos(
            (upVec.x * cVec.x + upVec.y * cVec.y)
            // durch länge upVec (1)
            // mal länge cVec (1)
        );
        const degRotation = (rotation / (2 * Math.PI)) * 360;
        return cVec.x < 0 ? -degRotation : degRotation;
    }

    static DefaultStyle() {
        return (
            <React.Fragment>
                <div className={'bullet-nose'}/>
                <div className={'bullet-tail'}>
                    <div className={'top-line'}></div>
                    <div className={'bottom-line'}></div>
                </div>
            </React.Fragment>
        );
    }

    private impactPosition(impactPosition: Vec2, effectProgress: number): React.CSSProperties {
        return {
            left: impactPosition.x * this.props.scaling,
            bottom: impactPosition.y * this.props.scaling,
            width: 2 * this.props.scaling * effectProgress,
            height: 2 * this.props.scaling * effectProgress,
            opacity: 0.65 - (effectProgress * 0.5)
        };
    }
}

function normalise(v: Vec2) {
    const factorize = 1 / Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2));
    return {
        x: v.x * factorize,
        y: v.y * factorize,
    };
}