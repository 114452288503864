import * as React from 'react';
import './loadingSpinner.css'

export default function Loading(props: {}) {
    return (<div className={'center'} style={{width: 80, height: 80}}>
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>);
}
