export interface AuthenticationState {
    isAuth: boolean;
    authToken: string | undefined;
    name: string | undefined;
}

export function buildInitialAuthenticationState(): AuthenticationState {
    return {
        isAuth: false,
        authToken: '',
        name: undefined
    };
}