import * as React from 'react';
import {ArrowBack} from "@mui/icons-material";

interface ComponentProps {
    closeUserTab?: () => void;
    title: string;
}

export default class UserTabHead extends React.Component<ComponentProps> {

    render() {
        return (
            <div className={'user-tab-head'}>
                {
                    this.props.closeUserTab !== undefined &&
                    <div className={'icon'} onClick={this.props.closeUserTab}>
                        <ArrowBack/>
                    </div>
                }
                <div className={'title'}>
                    {this.props.title}
                </div>

                {
                    this.props.closeUserTab !== undefined &&
                    <div className={'icon'} />
                }
            </div>
        );
    }

}