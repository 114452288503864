import * as React from 'react';
import {Dispatch} from 'react';
import {State} from "../../../Redux/State";
import {Action} from "../../../Redux/Action";
import {connect} from "react-redux";
import GameFieldStyle from "../../GameFieldStyle";
import './menu.css';
import {ScreenView} from "../../Layout";
import {buildChangeViewAction} from "../../../Redux/Actions/ChangeViewAction";

interface ComponentProps {
    gameFieldStyle: React.CSSProperties;
}

interface StateProps {
}

interface DispatchProps {
    changeView: (view: ScreenView) => void
}

type Props = StateProps & DispatchProps & ComponentProps

class MenuView extends React.Component<Props> {
    static mapStateProps(state: State): StateProps {
        return {};
    }

    static mapDispatchProps(dispatch: Dispatch<Action>): DispatchProps {
        return {
            changeView: (v: ScreenView) => dispatch(buildChangeViewAction(v))
        };
    }

    render() {
        return (
            <GameFieldStyle gameFieldStyle={this.props.gameFieldStyle}>
                <div className={'menu'}>
                    <div className={'h-flex'}>
                        <div className={'v-flex'}>
                            <div className={'header'}>
                                Bullet Race
                            </div>
                            <button className={'button'} onClick={() => this.props.changeView('GAME')}>Play Match</button>
                            <button className={'button'} disabled={true}>Leaderboard</button>
                            <button className={'button'} disabled={true}>Customize</button>
                            <button className={'button'} disabled={true}>Settings</button>
                        </div>
                    </div>
                </div>
            </GameFieldStyle>
        );
    }
}

export default connect(MenuView.mapStateProps, MenuView.mapDispatchProps)(MenuView);