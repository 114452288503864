import {PlayerConnectedMessage} from "./ServerMessage/PlayerConnectedMessage";
import {UpdateGameStateMessage} from "./ServerMessage/UpdateGameStateMessage";

export type GameServerMessage =
    | PlayerConnectedMessage
    | UpdateGameStateMessage


export enum GameServerMessageType {
    PLAYER_CONNECTED = 0,
    UPDATE_GAME_STATE = 1,
}