import * as React from 'react';
import {Dispatch} from 'react';
import {connect} from "react-redux";
import {State} from "../../../Redux/State";
import {Action} from "../../../Redux/Action";
import UserTabHead from "../UserTabHead";
import './auth.css';
import {AuthenticationState} from "../../../Redux/State/AuthenticationState";
import {networkManager} from "../../../index";
import {createSignOutClientRequest} from "../../../Api/AuthConnection/Requests/SignOut";
import {buildSetAuthenticatedStateAction} from "../../../Redux/Actions/SetAuthenticatedStateAction";
import {FriendListEntry, FriendStatus} from "../../../Api/LobbyNetworkMessages/Entity/FriendListEntry";

interface ComponentProps {
    closeUserTab?: () => void;
}

interface StateProps {
    auth: AuthenticationState;
    onlineFriends: FriendListEntry[];
}

interface DispatchProps {
    logout: () => void;
}

interface InternalState {
}

type Props = StateProps & DispatchProps & ComponentProps

class UserTabAuth extends React.Component<Props, InternalState> {

    static mapStateProps(state: State): StateProps {
        return {
            onlineFriends: state.friendList.online,
            auth: state.authentication
        };
    }

    static mapDispatchProps(dispatch: Dispatch<Action>): DispatchProps {
        return {
            logout: () => dispatch(buildSetAuthenticatedStateAction(undefined, undefined))
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className={'auth-user-tab'}>
                    <UserTabHead closeUserTab={this.props.closeUserTab}
                                 title={this.props.auth.name !== undefined ? this.props.auth.name : 'Account'}/>
                    <div className={'friend-list'}>
                        <div className={'friend-list-header'}>Online Player</div>
                        {
                            this.props.onlineFriends.map((f: FriendListEntry) => {
                                return (<div className={'friend-list-entry'} key={'friend-' + f.name}>
                                    <div className={'name'}>{f.name}</div>
                                    <div className={'status ' + f.status}>{this.statusTxt(f.status)}</div>
                                </div>);
                            })
                        }
                    </div>
                    <div className={'logout-wrap'}>
                        <button className={'logout'} onClick={() => this.signOut()}>Sign Out</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    statusTxt(status: FriendStatus): string {
        switch (status) {
            case "afk":
                return 'AFK';
            case "offline":
                return 'offline';
            case "online":
                return 'online';
            case "in-game":
                return 'in game';
            case "in-queue":
                return 'in queue';
        }
    }

    signOut() {
        if (this.props.auth.authToken !== undefined) {
            networkManager.sendAuthRequest(createSignOutClientRequest(this.props.auth.authToken)).then(() => {
                this.props.logout();
            });
        }
    }

}

export default connect(UserTabAuth.mapStateProps, UserTabAuth.mapDispatchProps)(UserTabAuth);