import {LobbyAuthenticationAnswerMessage} from "./ServerMessage/LobbyAuthenticationAnswerMessage";
import {LobbyUpdateFriendListMessage} from "./ServerMessage/LobbyUpdateFriendListMessage";

export type LobbyServerMessage =
    | LobbyAuthenticationAnswerMessage
    | LobbyUpdateFriendListMessage


export enum LobbyServerMessageType {
    AUTHENTICATION_ANSWER = 0,
    UPDATE_FRIEND_LIST = 1,
}