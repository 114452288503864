import * as React from 'react';
import GameRenderer from "../Game/GameRenderer";
import {GameServerMessage, GameServerMessageType} from "./Network/GameServerMessage";
import {emptyNetworkState, NetworkGameState} from "./Network/NetworkGameState/NetworkGameState";
import {buildGameShootMessage} from "./Network/ClientMessage/GameShootMessage";
import GameFieldStyle from "../Components/GameFieldStyle";
import UserTab from "../Components/UserTab/UserTab";
import {WebSocketHandler} from "../Api/WebSocketHandler";
import {GameClientMessage} from "./Network/GameClientMessage";
import {networkManager} from "../index";
import {LobbyClientMessageType} from "../Api/LobbyNetworkMessages/LobbyClientMessage";

interface DebugInfo {
    loadedLevels: number[];
}

interface ComponentProps {
    backgroundSize: { x: number, y: number };
    gameField: { x: number, y: number };
    scaling: number;
    exitGame: () => void;
}

interface InternalState {
    debug: DebugInfo;
    gameState: NetworkGameState;
    gameStateUpdateTime: number;
    playerScore: number;
    playerHeight: number;
    playerIndex: number;
    playerCamera: number;
}

export default class GameHandler extends React.Component<ComponentProps, InternalState> {

    private webSocket: WebSocketHandler<GameClientMessage, GameServerMessage>;

    constructor(props: ComponentProps) {
        super(props);
        this.state = {
            debug: {
                loadedLevels: []
            },
            gameState: emptyNetworkState(),
            gameStateUpdateTime: (new Date()).getTime(),
            playerScore: 0,
            playerHeight: 0,
            playerIndex: -1,
            playerCamera: -10
        };
        this.webSocket = networkManager.connectAndGetGameServer();
    }

    componentDidMount() {
        this.registerWebSocket();
    }


    private registerWebSocket() {
        networkManager.lobbyWs.send({
            type: LobbyClientMessageType.STATUS_UPDATE,
            status: 'in-game'
        });
        this.webSocket = networkManager.connectAndGetGameServer();
        this.webSocket.setReceiveCallback((data: GameServerMessage) => {
            switch (data.type) {
                case GameServerMessageType.PLAYER_CONNECTED:
                    break;
                case GameServerMessageType.UPDATE_GAME_STATE:
                    const index = GameRenderer.getIndex(data.gameState, data.yourId);
                    this.setState({
                        ...this.state,
                        debug: this.buildDebug(data.gameState),
                        gameState: data.gameState,
                        gameStateUpdateTime: (new Date()).getTime(),
                        playerIndex: index,
                        playerScore: data.gameState.playerList[index].score,
                        playerHeight: data.gameState.playerList[index].projectile.position.y,
                        playerCamera: GameHandler.determineCameraHeight(
                            data.gameState.playerList[index].projectile.position.y,
                            this.state.playerCamera,
                            index)
                    });
                    break;
            }
        });
        this.webSocket.setCloseAction(() => {
            this.props.exitGame();
        });
    }

    render() {
        return (
            <GameFieldStyle
                gameFieldClick={(ev) => this.gameClickShoot(ev)}
                gameFieldStyle={this.gameFieldStyle()}
                exitGame={() => this.exitGame()}
            >
                <div style={{position: "absolute", userSelect: "none"}}>
                    {this.state.debug.loadedLevels.map((l, i) => <span key={l}>{i > 0 ? ',' : ''}{l}</span>)}<br/>
                    {Math.floor(this.state.playerHeight)}
                </div>
                <GameRenderer
                    fieldSize={this.props.gameField}
                    scaling={this.props.scaling}
                    gameState={this.state.gameState}
                    playerCamera={this.state.playerCamera}
                    playerIndex={this.state.playerIndex}
                    playerScore={this.state.playerScore}
                    updateTime={this.state.gameStateUpdateTime}
                />
            </GameFieldStyle>
        );
    }

    exitGame() {
        networkManager.closeGameServerConnection();
        networkManager.lobbyWs.send({
            type: LobbyClientMessageType.STATUS_UPDATE,
            status: 'online'
        });
        this.props.exitGame();
    }

    gameClickShoot(ev: React.MouseEvent<HTMLDivElement>) {
        let backgroundX = this.props.backgroundSize.x;
        let leftSideBar = 0;
        if (this.props.backgroundSize.x - this.props.gameField.x > UserTab.USER_TAB_WIDTH) {
            backgroundX -= UserTab.USER_TAB_WIDTH;
            leftSideBar = UserTab.USER_TAB_WIDTH;
        }
        const evBotLeft = {
            x: ev.clientX - leftSideBar,
            y: this.props.backgroundSize.y - ev.clientY
        };
        const y = (evBotLeft.y - ((this.props.backgroundSize.y - this.props.gameField.y) * 0.5)) / this.props.scaling;
        const x = (evBotLeft.x - ((backgroundX - this.props.gameField.x) * 0.5)) / this.props.scaling;
        const target = {x: x, y: y + this.state.playerCamera};
        this.webSocket.send(buildGameShootMessage(target, target));
    }

    private gameFieldStyle(): React.CSSProperties {
        return {
            width: this.props.gameField.x,
            height: this.props.gameField.y,
            backgroundPositionY: (this.state.playerCamera * this.props.scaling) + 'px'
        };
    }

    private static determineCameraHeight(playerHeight: number, previousHeight: number, pIndex: number): number {
        const visibleHeight = 40;
        const minSize = 0.4;
        const maxSize = 0.8;

        const min = previousHeight + (visibleHeight * minSize);
        const max = previousHeight + (visibleHeight * maxSize);
        if (playerHeight < min) {
            return playerHeight - (visibleHeight * minSize);
        }
        if (playerHeight > max) {
            return playerHeight - (visibleHeight * maxSize);
        }
        return previousHeight;
    }

    private buildDebug(gameState: NetworkGameState): DebugInfo {
        let lvl: number[] = [];
        gameState.plankList.forEach(p => {
            if (lvl.indexOf(p.level) === -1) {
                lvl.push(p.level);
            }
        });
        return {
            loadedLevels: lvl
        };
    }
}