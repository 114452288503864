
export function createSignOutClientRequest(token: string): SignOutClientRequest {
    return {
        route: 'signout',
        token: token
    }
}

export interface SignOutClientRequest {
    route: 'signout',
    token: string
}

export interface SignOutServerResponse {
}

