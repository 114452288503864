import * as React from 'react';
import {NetworkPlank} from "../Network/NetworkGameState/NetworkGameState";
import {GameObjectEffect} from "../GameObjectEffect";

interface ComponentProps {
    scaling: number;
    plank: NetworkPlank;
    updateTime: number;
}

export class Plank extends React.Component<ComponentProps> {

    private effects: GameObjectEffect[] = []

    render() {
        this.effects = this.effects.filter(e => !e.shouldDie(this.props.updateTime))
        this.props.plank.effects.forEach(e => this.effects.push(new GameObjectEffect(e, this.props.updateTime, 100)))
        return (
            <div className={'plank' + this.resolveEffectClasses()} style={this.plankStyle()}/>
        );
    }

    private plankStyle(): React.CSSProperties {
        return {
            width: this.props.plank.size.x * this.props.scaling,
            height: this.props.plank.size.y * this.props.scaling,
            left: this.props.plank.position.x * this.props.scaling,
            bottom: this.props.plank.position.y * this.props.scaling,
            opacity: Math.max(0.5, this.props.plank.lifePoints / 5),
        };
    }

    private resolveEffectClasses(): string {
        let classes = ''
        if(this.props.plank.lifePoints === 2) {classes += ' danger'}
        if(this.props.plank.lifePoints === 1) {classes += ' more-danger'}
        if(this.props.plank.lifePoints === 0) {classes += ' broken'}
        if(this.effects.find(e => e.effect.type === 'hit') !== undefined) {
            classes += ' is-hit'
        }
        return classes
    }
}