import {FriendListEntry} from "../../Api/LobbyNetworkMessages/Entity/FriendListEntry";

export interface FriendListState {
    online: FriendListEntry[];
}

export function buildInitialFriendListState(): FriendListState {
    return {
        online: []
    };
}