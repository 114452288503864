import {WebSocketHandler} from "./WebSocketHandler";
import {AuthClientMessage} from "./AuthConnection/AuthClientMessage";
import {LobbyClientMessage, LobbyClientMessageType} from "./LobbyNetworkMessages/LobbyClientMessage";
import {LobbyServerMessage} from "./LobbyNetworkMessages/LobbyServerMessage";
import {LobbyAuthenticationMessage} from "./LobbyNetworkMessages/ClientMessage/AuthenticationMessage";
import {CheckTokenResponse, createCheckTokenRequest} from "./AuthConnection/Requests/CheckToken";
import {store} from "../store";
import {buildSetAuthenticatedStateAction} from "../Redux/Actions/SetAuthenticatedStateAction";
import {LobbyUnauthenticateMessage} from "./LobbyNetworkMessages/ClientMessage/UnauthenticateMessage";
import {GameClientMessage} from "../Game/Network/GameClientMessage";
import {GameServerMessage} from "../Game/Network/GameServerMessage";
import {env} from "../env";

export interface AuthData {
    token: string | undefined;
}

export class NetworkManager {

    static readonly authServer: string = env('REACT_APP_REQUEST_PROTOCOL') + env('REACT_APP_AUTH_HOST') + '/';
    static readonly lobbyServer: string = env('REACT_APP_WEBSOCKET_PROTOCOL') + env('REACT_APP_LOBBY_HOST') + '/';
    static readonly gameServer: string = env('REACT_APP_WEBSOCKET_PROTOCOL') + env('REACT_APP_GAME_HOST') + '/';

    private auth: AuthData;
    lobbyWs: WebSocketHandler<LobbyClientMessage, LobbyServerMessage>;
    gameWs: WebSocketHandler<GameClientMessage, GameServerMessage> | undefined = undefined;

    constructor() {
        this.auth = {token: undefined};
        this.lobbyWs = new WebSocketHandler<LobbyClientMessage, LobbyServerMessage>(NetworkManager.lobbyServer, this.auth);
        const token = localStorage.getItem('localLoginToken');
        if (token !== null) {
            this.sendAuthRequest(createCheckTokenRequest(token)).then(
                (response) => {
                    response.text().then((bodyTxt: string) => {
                        const body = JSON.parse(bodyTxt) as CheckTokenResponse;
                        if (body.status === 'authenticated') {
                            this.setAuthenticated(token);
                            store.dispatch(buildSetAuthenticatedStateAction(token, body.name));
                        }
                    });
                }
            );
        }
    }

    setUnauthenticated() {
        this.auth = {token: undefined};

        this.sendUnauthenticatedToLobby()
    }

    setAuthenticated(token: string) {
        this.auth = {token: token};

        this.sendAuthenticatedToLobby(token);
    }

    connectAndGetGameServer(): WebSocketHandler<GameClientMessage, GameServerMessage> {
        if(this.gameWs !== undefined) {
            if(this.gameWs.isClosed) {
                this.gameWs = new WebSocketHandler<GameClientMessage, GameServerMessage>(NetworkManager.gameServer, this.auth);
            }
        } else {
            this.gameWs = new WebSocketHandler<GameClientMessage, GameServerMessage>(NetworkManager.gameServer, this.auth);
        }
        return this.gameWs
    }

    closeGameServerConnection() {
        if(this.gameWs !== undefined) {
            this.gameWs.close()
        }
    }

    private sendAuthenticatedToLobby(token: string) {
        const authMsg: LobbyAuthenticationMessage = {
            type: LobbyClientMessageType.AUTHENTICATION,
            token: token
        };
        this.lobbyWs.send(authMsg);
    }
    private sendUnauthenticatedToLobby() {
        const authMsg: LobbyUnauthenticateMessage = {
            type: LobbyClientMessageType.UNAUTHENTICATE,
        };
        this.lobbyWs.send(authMsg);
    }

    async sendAuthRequest(body: AuthClientMessage): Promise<Response> {
        let headers: HeadersInit = [
            ['Content-Type', 'application/json']
        ];
        if (this.auth.token !== undefined) {
            headers.push(['Authorization', this.auth.token]);
        }
        return fetch(NetworkManager.authServer + body.route, {
            mode: 'cors',
            body: JSON.stringify(body),
            method: 'POST',
            headers: headers
        });
    }

    compareToken(token: string) {
        return token === this.auth.token;
    }

}