import * as React from 'react';
import {Dispatch} from 'react';
import {connect} from "react-redux";
import {State} from "../../../Redux/State";
import {Action} from "../../../Redux/Action";
import UserTabHead from "../UserTabHead";
import './register.css';
import {networkManager} from "../../../index";
import {
    createSignUpClientRequest,
    SignUpServerResponse,
    SignUpServerResponseError
} from "../../../Api/AuthConnection/Requests/SignUp";

interface ComponentProps {
    closeUserTab?: () => void;
    openUserTabLogin: () => void;
}

interface StateProps {
}

interface DispatchProps {
}

interface InternalState {
    nameInput: string;
    passwordInput: string;
    registeredName: string | undefined;
    registerError: 'none' | SignUpServerResponseError
}

type Props = StateProps & DispatchProps & ComponentProps

class UserTabRegister extends React.Component<Props, InternalState> {

    static mapStateProps(state: State): StateProps {
        return {};
    }

    static mapDispatchProps(dispatch: Dispatch<Action>): DispatchProps {
        return {};
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            nameInput: '',
            passwordInput: '',
            registeredName: undefined,
            registerError: 'none'
        };
    }

    render() {
        return (
            <React.Fragment>
                <UserTabHead closeUserTab={this.props.closeUserTab} title={'Create Account'}/>
                {this.state.registeredName === undefined && this.registerInputs()}
                {this.state.registeredName !== undefined && this.registerSuccess()}
            </React.Fragment>
        );
    }

    registerSuccess() {
        return (
            <div className={'register-elements'}>
                <div className={'h-center'}>
                    <div className={'register-link'} onClick={this.props.openUserTabLogin}>
                        The Account '{this.state.registeredName}' was registered!<br/>SignIn now!
                    </div>
                </div>
            </div>
        );
    }

    registerInputs() {
        return (
            <div className={'register-elements'}>
                <input
                    type={'text'}
                    className={'input'}
                    id={'name'}
                    placeholder={'Name'}
                    onChange={(ev) => this.setState({...this.state, nameInput: ev.target.value})}
                    value={this.state.nameInput}
                />
                <input
                    type={'password'}
                    className={'input'}
                    id={'password'}
                    placeholder={'Password'}
                    onChange={(ev) => this.setState({...this.state, passwordInput: ev.target.value})}
                    value={this.state.passwordInput}
                />
                <div className={'h-center'}>
                    <button onClick={() => this.fetchRegister()}>Create Account</button>
                </div>
                <div className={'h-center'}>
                    <div className={'error'}>
                        {this.state.registerError === 'name-taken' && <span>Name is not available!</span>}
                    </div>
                </div>
                <div className={'h-center'}>
                    <div className={'register-link'} onClick={this.props.openUserTabLogin}>
                        Already have an account?<br/>SignIn now!
                    </div>
                </div>
            </div>
        );
    }

    fetchRegister() {
        networkManager
            .sendAuthRequest(createSignUpClientRequest(this.state.nameInput, this.state.passwordInput))
            .then((r: Response) => {
                if (r.status === 200 || r.status === 204) {
                    r.text().then((t) => {
                        const obj = JSON.parse(t) as SignUpServerResponse;
                        if(obj.status === 'success') {
                            this.setState({
                                ...this.state,
                                registeredName: obj.name
                            });
                        } else {
                            console.log('hei')
                            this.setState({
                                registerError: obj.error
                            })
                        }
                    });
                }
            });
    }

}

export default connect(UserTabRegister.mapStateProps, UserTabRegister.mapDispatchProps)(UserTabRegister);