import {applyMiddleware, legacy_createStore as createStore} from 'redux';
import {MainReducer} from "./Redux/Reducer";
import {buildInitialState} from "./Redux/State";

export const store = createStore(
    MainReducer,
    buildInitialState(),
    applyMiddleware()
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch