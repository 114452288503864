
export function createSignInClientRequest(name: string, password: string): SignInClientRequest {
    return {
        route: 'signin',
        name: name,
        password: password
    }
}

export interface SignInClientRequest {
    route: 'signin'
    name: string
    password: string
}

export interface SignInServerResponse {
    status: 'authenticated'
    token: string
}