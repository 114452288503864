import * as React from 'react';
import {Vec2} from "./Math/Vec2";
import {Plank} from "./Objects/Plank";
import {Projectile} from "./Objects/Projectile";
import {NetworkGameState} from "./Network/NetworkGameState/NetworkGameState";
import './projectiles.css';

interface ComponentProps {
    fieldSize: Vec2;
    scaling: number;
    playerCamera: number;
    playerIndex: number;
    playerScore: number
    gameState: NetworkGameState;
    updateTime: number;
}

class GameRenderer extends React.Component<ComponentProps> {

    render() {
        return (
            <div className={'game-event-listener'}>
                <div className={'scoreboard'}>
                    Score<br />{this.props.playerScore}
                </div>
                <div className={'camera-move'} style={this.cameraStyle()}>
                    <div className={'floor'}/>
                    {
                        this.props.gameState.plankList.map((p, i) =>
                            <Plank
                                key={'pl-' + i}
                                scaling={this.props.scaling}
                                plank={p}
                                updateTime={this.props.updateTime}
                            />
                        )
                    }
                    {
                        this.props.gameState.playerList.map((p, i) => {
                                return <Projectile
                                    key={'pr-' + i}
                                    scaling={this.props.scaling}
                                    projectile={p.projectile}
                                    ownedByUser={this.props.playerIndex === i}
                                    updateTime={this.props.updateTime}
                                />;
                            }
                        )
                    }
                </div>
            </div>
        );
    }

    private cameraStyle(): React.CSSProperties {
        return {
            bottom: -this.props.playerCamera * this.props.scaling
        };
    }

    static getIndex(state: NetworkGameState, yourId: string) {
        for (let i = 0; i < state.playerList.length; i++) {
            if (state.playerList[i].id === yourId) {
                return i;
            }
        }
        return -1;
    }


}

export default GameRenderer;