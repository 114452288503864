import * as React from 'react';
import {Dispatch} from 'react';
import {connect} from "react-redux";
import {State} from "../../../Redux/State";
import {Action} from "../../../Redux/Action";
import UserTabHead from "../UserTabHead";
import './login.css';
import {networkManager} from '../../..';
import {buildSetAuthenticatedStateAction} from "../../../Redux/Actions/SetAuthenticatedStateAction";
import {createSignInClientRequest, SignInServerResponse} from "../../../Api/AuthConnection/Requests/SignIn";

interface ComponentProps {
    closeUserTab?: () => void;
    openUserTabRegister: () => void;
}

interface StateProps {
}

interface DispatchProps {
    signIn: (token: string, name: string) => void;
}

interface InternalState {
    nameInput: string;
    passwordInput: string;
}

type Props = StateProps & DispatchProps & ComponentProps

class UserTabLogin extends React.Component<Props, InternalState> {

    static mapStateProps(state: State): StateProps {
        return {};
    }

    static mapDispatchProps(dispatch: Dispatch<Action>): DispatchProps {
        return {
            signIn: (token: string, name: string) => dispatch(buildSetAuthenticatedStateAction(token, name))
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            nameInput: '',
            passwordInput: ''
        };
    }

    render() {
        return (
            <React.Fragment>
                <UserTabHead closeUserTab={this.props.closeUserTab} title={'Sign In'}/>
                <div className={'login-elements'}>
                    <input
                        type={'text'}
                        className={'input'}
                        id={'name'}
                        placeholder={'Name'}
                        onChange={(ev) => this.setState({...this.state, nameInput: ev.target.value})}
                        value={this.state.nameInput}
                    />
                    <input
                        type={'password'}
                        className={'input'}
                        id={'password'}
                        placeholder={'Password'}
                        onChange={(ev) => this.setState({...this.state, passwordInput: ev.target.value})}
                        value={this.state.passwordInput}
                    />
                    <div className={'h-center'}>
                        <button onClick={() => this.fetchLogin()}>Sign In</button>
                    </div>
                    <div className={'h-center'}>
                        <div className={'register-link'} onClick={this.props.openUserTabRegister}>
                            Have no account? Create one now!
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    fetchLogin() {
        networkManager
            .sendAuthRequest(createSignInClientRequest(this.state.nameInput, this.state.passwordInput))
            .then((r: Response) => {
                if (r.status === 200 || r.status === 204) {
                    r.text().then((t) => {
                        const obj = JSON.parse(t) as SignInServerResponse;
                        this.props.signIn(obj.token, this.state.nameInput);
                    });
                }
            });
    }
}

export default connect(UserTabLogin.mapStateProps, UserTabLogin.mapDispatchProps)(UserTabLogin);