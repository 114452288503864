import * as React from 'react';
import {Dispatch} from 'react';
import {State} from "../Redux/State";
import {Action} from "../Redux/Action";
import {connect} from "react-redux";
import Loading from "./Loading";
import {Vec2} from "../Game/Math/Vec2";
import GameHandler from "../Game/GameHandler";
import MenuView from "./Views/Menu/MenuView";
import UserTab from "./UserTab/UserTab";
import {buildChangeViewAction} from "../Redux/Actions/ChangeViewAction";

export type ScreenView = 'MENU' | 'GAME' | 'HIGH_SCORES' | 'CUSTOMIZATIONS' | 'SETTINGS'

interface StateProps {
    view: ScreenView;
}

interface DispatchProps {
    setView: (view: ScreenView) => void
}

interface InternalState {
    backgroundSize: Vec2 | null;
    gameField: Vec2 | null;
    scaling: number;
    gameFieldByWidth: boolean;
}

type Props = StateProps & DispatchProps

class Layout extends React.Component<Props, InternalState> {
    static mapStateProps(state: State): StateProps {
        return {
            view: state.view
        };
    }

    static mapDispatchProps(dispatch: Dispatch<Action>): DispatchProps {
        return {
            setView: (view: ScreenView) => dispatch(buildChangeViewAction(view))
        };
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            backgroundSize: null,
            gameField: null,
            scaling: 1,
            gameFieldByWidth: false
        };
    }

    componentDidMount() {
        this.listenToWindowSize();
    }

    render() {
        if (this.state.backgroundSize === null || this.state.gameField === null) {
            return <div className={'background'} id={'background'}><Loading/></div>;
        }
        const gfs = {
            width: this.state.gameField.x,
            height: this.state.gameField.y,
        };
        const bg: Vec2 = this.state.backgroundSize;
        const gf: Vec2 = this.state.gameField;
        const s: number = this.state.scaling;
        const sideBarSpace = (this.state.backgroundSize.x - this.state.gameField.x)
        const topBottomSpace = (this.state.backgroundSize.y - this.state.gameField.y)
        const classAdd = (this.state.gameFieldByWidth) ? ' mono' : ' gradient'
        return (
            <div className={'background' + classAdd} id={'background'}>
                <UserTab freeSideSpace={sideBarSpace} freeTopBottomSpace={topBottomSpace}>
                    {this.props.view === 'MENU' && <MenuView gameFieldStyle={gfs}/>}
                    {this.props.view === 'GAME' && <GameHandler backgroundSize={bg} gameField={gf} scaling={s} exitGame={() => this.openMenu()}/>}
                    {this.props.view === 'HIGH_SCORES' && <div/>}
                    {this.props.view === 'CUSTOMIZATIONS' && <div/>}
                    {this.props.view === 'SETTINGS' && <div/>}
                </UserTab>
            </div>
        );
    }

    private listenToWindowSize() {
        // @ts-ignore
        if (!listenToScreenSize((size: Vec2) => {
            const byWidth = Layout.gameFieldByWidth(size)
            const gameField = Layout.gameField(size, byWidth);
            this.setState({
                backgroundSize: size,
                gameField: gameField,
                scaling: Layout.scaling(gameField),
                gameFieldByWidth: byWidth
            });
        })) {
            setTimeout(() => this.listenToWindowSize(), 100);
        }
    }

    private static gameFieldByWidth(backgroundSize: Vec2): boolean {
        return backgroundSize.y / backgroundSize.x > 2
    }
    private static gameField(backgroundSize: Vec2, byWidth: boolean): Vec2 {
        if (byWidth) {
            // full width and cut height
            return {
                x: backgroundSize.x,
                y: backgroundSize.x * 2
            };
        } else {
            // full height and cut width
            return {
                x: backgroundSize.y * 0.5,
                y: backgroundSize.y
            };
        }
    }

    private static scaling(size: Vec2) {
        return (size.x / 20);
    }

    private openMenu() {
        this.props.setView('MENU')
    }
}

export default connect(Layout.mapStateProps, Layout.mapDispatchProps)(Layout);