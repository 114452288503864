import {ScreenView} from "../../Components/Layout";
import {State} from "../State";

export interface ChangeViewAction {
    type: 'ChangeViewAction'
    view: ScreenView
}

export function buildChangeViewAction(view: ScreenView): ChangeViewAction {
    return {
        type: 'ChangeViewAction',
        view: view
    }
}

export function reduceChangeViewAction(state: State, action: ChangeViewAction): State {
    return {
        ...state,
        view: action.view
    }
}