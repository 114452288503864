import {AuthData} from "./NetworkManager";

export class WebSocketHandler<SendingMessages, ReceivingMessage> {

    private socket: WebSocket;
    private auth: AuthData;
    isReady: boolean = false
    isClosed: boolean = false
    private receiveCallback: (data: ReceivingMessage) => void = (data: ReceivingMessage) => {
        console.log('unhandled: ', data);
    };

    constructor(url: string, auth: AuthData) {
        console.log('Create Websocket for url: ' + url)
        this.socket = new WebSocket(url);
        this.auth = auth;
        this.socket.onopen = () => {
            this.isReady = true
            console.log('WebSocket opened: ' + url)
        };
        this.socket.onmessage = (m) => {
            this.receiveCallback(JSON.parse(m.data.toString()));
        };
        this.socket.onclose = () => {
            console.log('WebSocket Closed');
        }
    }

    send(data: SendingMessages) {
        if(this.isReady) {
            this.socket.send(JSON.stringify(data));
        } else if (!this.isClosed) {
            setTimeout(() => this.send(data), 50)
        }
    }

    setReceiveCallback(func: (data: ReceivingMessage) => void) {
        this.receiveCallback = func;
    }

    setCloseAction(param: () => void) {
        this.socket.onclose = () => {
            this.isReady = false
            this.isClosed = true
            param()
        }
    }

    close() {
        this.isReady = false
        this.isClosed = true
        this.socket.close()
    }

}

