import {NetworkGameObjectEffect} from "./Network/NetworkGameState/NetworkGameState";

export class GameObjectEffect {

    readonly effect: NetworkGameObjectEffect;
    readonly created: number
    readonly duration: number
    readonly dieAt: number;

    constructor(effect: NetworkGameObjectEffect, created: number, duration: number) {
        this.created = created;
        this.duration = duration;
        this.dieAt = created + duration;
        this.effect = effect;
    }

    progress(updateTime: number) {
        const elapsed = updateTime - this.created;
        return elapsed / this.duration;
    }

    shouldDie(updateTime: number) {
        return this.dieAt < updateTime;
    }
}