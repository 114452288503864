import { Close } from '@mui/icons-material';
import * as React from 'react';

interface ComponentProps {
    children: React.ReactNode
    gameFieldStyle?: React.CSSProperties
    gameFieldClick?: (ev: React.MouseEvent<HTMLDivElement>) => void
    exitGame?: () => void
}

class GameFieldStyle extends React.Component<ComponentProps> {
    render() {
        let add = (this.props.gameFieldClick !== undefined) ? ' pointer' : ''
        return (
            <React.Fragment>
                <div className={'game-field-ui'}>
                    {
                        this.props.exitGame !== undefined &&
                        <div className={'exit-btn'} onClick={this.props.exitGame}>
                            <Close />
                        </div>
                    }

                </div>
                <div
                    className={'game-field-wrap' + add}
                    onMouseDownCapture={this.props.gameFieldClick}
                >
                    <div className={'game-field'} style={this.props.gameFieldStyle}>
                        <div className={'shadow'}/>
                        {this.props.children}
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

export default GameFieldStyle;