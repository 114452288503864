import {LobbyAuthenticationMessage} from "./ClientMessage/AuthenticationMessage";
import {LobbyUnauthenticateMessage} from "./ClientMessage/UnauthenticateMessage";
import {LobbyStatusUpdateMessage} from "./ClientMessage/StatusUpdateMessage";

export type LobbyClientMessage =
    | LobbyAuthenticationMessage
    | LobbyUnauthenticateMessage
    | LobbyStatusUpdateMessage


export enum LobbyClientMessageType {
    AUTHENTICATION = 0,
    UNAUTHENTICATE = 1,
    STATUS_UPDATE = 2,
}

