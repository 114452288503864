
export function createCheckTokenRequest(token: string): CheckTokenRequest {
    return {
        route: 'check-token',
        token: token
    }
}

export interface CheckTokenRequest {
    route: 'check-token'
    token: string
}

export type CheckTokenResponse = AuthenticatedCheckTokenResponse | UnauthenticatedCheckTokenResponse

interface AuthenticatedCheckTokenResponse {
    status: 'authenticated'
    name: string
}
interface UnauthenticatedCheckTokenResponse {
    status: 'unauthenticated'
}