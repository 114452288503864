import {ClientMessageType} from "../GameClientMessage";

interface V2 {
    x: number,
    y: number
}

export interface GameShootMessage {
    type: ClientMessageType.GAME_SHOOT
    shoot_target: V2,
    shoot_position: V2,
}

export function buildGameShootMessage(position: V2, target: V2): GameShootMessage {
    return {
        type: ClientMessageType.GAME_SHOOT,
        shoot_target: target,
        shoot_position: position,
    };
}