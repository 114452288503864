import {State} from "../State";
import {FriendListEntry} from "../../Api/LobbyNetworkMessages/Entity/FriendListEntry";

export interface SetOnlineFriendsAction {
    type: 'SetOnlineFriendsAction';
    onlineFriends: FriendListEntry[];
}

export function buildSetOnlineFriendsAction(onlineFriends: FriendListEntry[]): SetOnlineFriendsAction {
    return {
        type: 'SetOnlineFriendsAction',
        onlineFriends: onlineFriends
    };
}

export function reduceSetOnlineFriendsAction(state: State, action: SetOnlineFriendsAction): State {
    return {
        ...state,
        friendList: {
            ...state.friendList,
            online: action.onlineFriends
        }
    };
}