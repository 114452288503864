import {AuthenticationMessage} from "./ClientMessage/AuthenticationMessage";
import {GameShootMessage} from "./ClientMessage/GameShootMessage";

export type GameClientMessage =
    | AuthenticationMessage
    | GameShootMessage


export enum ClientMessageType {
    AUTHENTICATION = 0,
    GAME_SHOOT = 1,
}

